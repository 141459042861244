import React, { useEffect, useState } from 'react'
import { Box, Button } from '@sharecover-co/ui'
import { AdminLayout } from '../../layouts'

const Consent = () => {
    const [embedMethods, setEmbedMethods] = useState()

    useEffect(() => {
        setEmbedMethods(
            window.iagCiamEmbeds.embed({
                name: 'communications',
                domElementId: 'consent',
                environment: process.env.REACT_APP_CLP_ENV,
                brand: 'sharecover'
            })
        )
    }, [])

    return (
        <AdminLayout>
            <Box
                id="consent"
                sx={{
                    iframe: {
                        border: 'none'
                    }
                }}
            />

            <Button
                onClick={() =>
                    embedMethods.attemptToSubmitCurrentPage().then((result) => {
                        console.log(
                            `submitted consent: ${
                                result ? 'success' : 'failed '
                            }`
                        )
                    })
                }
            >
                Save
            </Button>
        </AdminLayout>
    )
}

export default Consent
