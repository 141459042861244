import React, { useState, useEffect } from 'react'
import {
    Heading,
    Box,
    Link,
    Badge,
    Text,
    Flex,
    Loading,
} from '@sharecover-co/ui'
import { API, graphqlOperation } from 'aws-amplify'
import { formatDate } from '../../../lib/formatters'
import { AdminLayout } from '../../layouts'
import * as queries from '../../../graphql/queries'

const ClaimComponent = ({ data }) => (
    <Box sx={{ my: 4 }}>
        <Link to={`/claim/${data.id}`}>
            <Heading as="h5" sx={{ color: 'secondary', ':hover': { color: 'primary' } }}>Claim {data.id}</Heading>
        </Link>
        <Text>
            Opened on {formatDate(data.createdAt)}
        </Text>
        <Box>
            <Badge
                sx={{
                    backgroundColor: 'lightGrey',
                    color: 'text',
                    fontSize: 12,
                    fontWeight: 500,
                    padding: '4px 5px 0',
                    marginRight: '10px',
                }}
            >
                Policy {data.policy.id}
            </Badge>

            <Badge
                sx={{
                    backgroundColor: 'lightGrey',
                    color: 'text',
                    fontSize: 12,
                    fontWeight: 500,
                    padding: '4px 5px 0',
                    marginRight: '10px',
                }}
            >
                {data.status}
            </Badge>
        </Box>
    </Box>
)

const Claims = () => {
    const [claims, setClaims] = useState()

    useEffect(() => {
        API.graphql(graphqlOperation(queries.listClaims))
            .then(({ data }) => {
                setClaims(data.listClaims.items.filter(item => item.policy))
            })
            .catch((error) => {
                console.error(error)
                setClaims([])
            })
    }, [])

    return (
        <AdminLayout>
            <Box sx={{ mb: 4 }}>
                <Heading as="h4">Your recent claims</Heading>
            </Box>
            <Box sx={{ mb: 4 }}>
                <Text>
                    To start a new claim, select your policy from the{' '}
                    <Link to="/">policy list</Link> and then lodge a new claim.
                </Text>
                {/* <Text>
                    To make a claim under your RideCover Comprehensive policy call <a href="tel:1300216233">1300 216 233</a>. Please have your policy number and vehicle registration number ready.
                </Text> */}
            </Box>

            {!claims && (
                <Flex sx={{ color: 'primary', my: 5 }}>
                    <Loading />
                </Flex>
            )}

            {claims?.length >= 1 &&
                claims.map((claim, i) => (
                    <ClaimComponent key={claim.id} data={claim} />
                ))}

            {claims?.length === 0 && (
                <>
                    <Text sx={{ mb: 4 }}>
                        No claims to display.
                    </Text>
                </>
            )}
        </AdminLayout>
    )
}

export default Claims
