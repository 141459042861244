import React, { useState, useEffect } from 'react'
import { Box, Heading, Text, Link } from '@sharecover-co/ui'
import { useParams } from 'react-router-dom'
import { API, graphqlOperation } from 'aws-amplify'
import { AdminLayout, LoadingLayout } from '../../layouts'
import { formatDate } from '../../../lib/formatters'
import * as queries from '../../../graphql/queries'

const Claim = () => {
    const [claim, setClaim] = useState()
    const { id } = useParams()

    useEffect(() => {
        API.graphql(graphqlOperation(queries.getClaim, { id }))
            .then(({ data }) => setClaim(data.getClaim))
            .catch((error) => console.log(error))
    }, [id])

    if (!claim) return <LoadingLayout />

    return (
        <AdminLayout>
            <Heading as="h4">Claim Details</Heading>
            <Heading as="h5" sx={{ mb: 4 }}>
                {claim.policy.description}
            </Heading>
            <Box sx={{ mb: 4 }}>
                <Text>Claim: {claim.id}</Text>
                <Text>Status: {claim.status}</Text>
                <Text>Created: {formatDate(claim.createdAt)}</Text>
                <Text>Updated: {formatDate(claim.updatedAt)}</Text>
            </Box>
            <Box sx={{ my: 4 }}>
                <Heading as="h5" sx={{ mb: 2 }}>
                    Policy Details
                </Heading>
                <Text>Policy: {claim.policy.id}</Text>
                <Text>
                    Start Date:{' '}
                    {formatDate(
                        claim.policy && (claim.policy.startDate || JSON.parse(claim.policy.data).startDate)
                    )}
                </Text>
                <Text>
                    End Date:{' '}
                    {formatDate(
                        claim.policy && (claim.policy.endDate || JSON.parse(claim.policy.data).endDate)
                    )}
                </Text>
                <Text>Purchased On: {formatDate(claim.policy.createdAt)}</Text>
                {claim.policy.cancelledAt && (
                    <Text>
                        Cancelled On: {formatDate(claim.policy.cancelledAt)}
                    </Text>
                )}
                <Text>Product: {claim.policy.product.name}</Text>
            </Box>
            <Box sx={{ my: 4 }}>
                <Heading as="h5" sx={{ mb: 2 }}>
                    Documents
                </Heading>

                {claim.documents.map((doc) => (
                    <Text key={doc.key}>
                        <Link href={doc.url} target="_blank" rel="noopener noreferrer">{doc.name}</Link>
                    </Text>
                ))}
            </Box>
        </AdminLayout>
    )
}

export default Claim
