import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { IsAuthenticated, ProtectedRoute } from '../hooks'

import NotFound from '../views/NotFound'
import Policies from '../views/policies/Policies'
import Policy from '../views/policies/Policy'
import Claims from '../views/claims/Claims'
import Claim from '../views/claims/Claim'
import Profile from '../views/profile/Profile'
import Consent from '../views/consent'
import PaymentMethods from '../views/payment-method/PaymentMethods'
import NewPaymentMethod from '../views/payment-method/NewPaymentMethod'
import CertificateOfCurrency from '../views/policies/CertificateOfCurrency'
import CertificateOfInsurance from '../views/policies/CertificateOfInsurance'
import Payments from '../views/Payments'
import withTracker from '../../utils/withTracker'
import FinancialYear from '../views/financialYear/FinancialYear'
// import Credits from '../views/referrals/Credits'
// import Refer from '../views/referrals/Refer'

const Routes = () => (
    <IsAuthenticated>
        <Switch>
            <ProtectedRoute path="/" exact component={withTracker(Policies)} />
            <ProtectedRoute
                path="/policy/:id"
                exact
                component={withTracker(Policy)}
            />
            <ProtectedRoute
                path="/policy/:id/payment-method"
                exact
                component={withTracker(PaymentMethods)}
            />
            <ProtectedRoute
                path="/policy/:id/payment-method/new"
                exact
                component={withTracker(NewPaymentMethod)}
            />
            <ProtectedRoute
                path="/policy/:id/certificate-of-currency"
                exact
                component={withTracker(CertificateOfCurrency)}
            />
            <ProtectedRoute
                path="/policy/:id/certificate-of-insurance"
                exact
                component={withTracker(CertificateOfInsurance)}
            />
            <ProtectedRoute
                path="/claims"
                exact
                component={withTracker(Claims)}
            />
            <ProtectedRoute
                path="/claim/:id"
                exact
                component={withTracker(Claim)}
            />
            <ProtectedRoute
                path="/profile"
                exact
                component={withTracker(Profile)}
            />
            <ProtectedRoute
                path="/profile/consent"
                exact
                component={withTracker(Consent)}
            />
            <ProtectedRoute
                path="/payments"
                exact
                component={withTracker(Payments)}
            />
            <ProtectedRoute
                path="/financials"
                exact
                component={withTracker(FinancialYear)}
            />
            {/* <Route path="/credits" exact component={withTracker(Credits)} /> */}
            {/* <Route path="/refer" exact component={withTracker(Refer)} /> */}
            <Route component={withTracker(NotFound)} />
        </Switch>
    </IsAuthenticated>
)

export default Routes
