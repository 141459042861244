import React from 'react'
import { Route, withRouter } from 'react-router-dom'
import Cookies from 'js-cookie'
import { goTo } from '../../lib/navigation'
const ProtectedRoute = ({ component: Component, ...routeProps }) => {
    const exp = JSON.parse(Cookies.get('aws-amplify-federatedInfo')).expires_at
    const authenticated = exp > new Date().getTime()
    return (
        <Route
            {...routeProps}
            render={(componentProps) =>
                authenticated ? (
                    <Component {...componentProps} />
                ) : (
                    goTo(`${process.env.REACT_APP_AUTH_URL}/refresh`)
                )
            }
        />
    )
}

export default withRouter(ProtectedRoute)
