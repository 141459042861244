import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import {
    Box,
    Heading,
    Text,
    Link,
    Divider,
    Button,
    parseFields,
    Modal,
    Loading,
} from '@sharecover-co/ui'
import { useModal } from 'react-modal-hook'
import { API, graphqlOperation } from 'aws-amplify'
import { DateTime } from 'luxon'
import { AdminLayout, LoadingLayout } from '../../layouts'
import { formatDate, formatCurrency } from '../../../lib/formatters'
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations'
import { customAlphabet } from 'nanoid'
import jwt from '../../../lib/jwt'
import get from 'lodash.get'
import { getUserCookie } from './../../../utils/getUserCookie'
const nanoid = customAlphabet('0123456789ABCDEFGHJKLMNPQRTUVWXYZ', 10)
const token = getUserCookie()
const email = jwt.getEmail(token)
const shareCoverUrl = `${process.env.REACT_APP_WEB_URL}`

const Policy = () => {
    const [policy, setPolicy] = useState()
    const [product, setProduct] = useState()
    const [lodaing, setLoading] = useState(false)
    const { id } = useParams()
    const history = useHistory()
    let startTime = new Date(get(policy, 'startDate', ''))
    startTime.setHours(get(policy, 'startTime', '').split(':')[0])
    startTime.setMinutes(get(policy, 'startTime', '').split(':')[1])
    const usedCreditFlag =
        JSON.parse(get(policy, 'data', '{}')).credit &&
        new Date(startTime).getTime() < new Date().getTime()
    const [showCreditModal, hideCreditModal] = useModal(() => (
        <Modal onRequestClose={hideCreditModal}>
            <Heading as="h4">Cancel Policy</Heading>
            <Text>
                This policy ({id}) was paid for using 1 or more free night
                credits. Please
                <Link href={`${shareCoverUrl}/contact`} target="_blank">
                    {' '}
                    contact us{' '}
                </Link>
                to cancel.
            </Text>
            <Button
                sx={{ mt: 3 }}
                onClick={() => {
                    hideCreditModal()
                    window.open(`${shareCoverUrl}/contact`, '_blank')
                }}
            >
                OK
            </Button>
        </Modal>
    ))
    const [showModal, hideModal] = useModal(() => (
        <Modal onRequestClose={hideModal}>
            <Heading as="h4">Cancel Policy</Heading>
            <Text>Are you sure you want to cancel this policy?</Text>
            <Button
                sx={{ mt: 3 }}
                onClick={() => {
                    hideModal()
                    cancelPolicy()
                }}
            >
                OK
            </Button>
        </Modal>
    ))
    useEffect(() => {
        API.graphql(graphqlOperation(queries.getPolicy, { id }))
            .then(({ data }) => {
                setPolicy(data.getPolicy)
                return API.graphql(
                    graphqlOperation(queries.getProduct, {
                        id: data.getPolicy.product.id,
                    })
                )
            })
            .then(({ data }) => {
                setProduct(data.getProduct)
            })
            .catch((error) => console.log(error))
    }, [id])

    const cancelPolicy = async () => {
        try {
            setLoading(true)
            const policyData = await API.graphql(
                graphqlOperation(queries.getPolicy, { id })
            )
            await API.graphql(graphqlOperation(mutations.cancelPolicy, { id }))
            if (JSON.parse(policyData.data.getPolicy.data).credit) {
                let input = {
                    id: nanoid(),
                    email: email.toLowerCase(),
                    policyId: policyData.data.getPolicy.id,
                    credit: JSON.parse(policyData.data.getPolicy.data).credit,
                    quoteId: policyData.data.getPolicy.quoteId,
                    type: 'cancelled',
                }
                await API.graphql(
                    graphqlOperation(mutations.addcreditLog, { input })
                )
                let credit = await API.graphql(
                    graphqlOperation(queries.getCredit, {
                        email: email.toLowerCase(),
                    })
                )
                input = {
                    ...credit.data.getCredit,
                    credit:
                        credit.data.getCredit.credit +
                        JSON.parse(policyData.data.getPolicy.data).credit * 1,
                }
                await API.graphql(
                    graphqlOperation(mutations.updateCredit, { input })
                )
            }
            setLoading(false)
            history.push('/')
        } catch (error) {
            setLoading(false)
            console.log(error)
        }
    }

    if (!policy || !product) return <LoadingLayout />

    const asideLinks = (
        <>
            <Box sx={{ my: 1 }}>
                <Link
                    href={`${process.env.REACT_APP_CLAIMS_URL}/start/${policy.id}`}
                >
                    Lodge a new claim
                </Link>
            </Box>
            {policy.paymentMethod &&
                DateTime.fromISO(policy.endDate) > DateTime.local() && (
                    <>
                        <Box sx={{ my: 1 }}>
                            <Link
                                to={{
                                    pathname: `${history.location.pathname}/payment-method`,
                                }}
                            >
                                Change my payment method
                            </Link>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Link
                                to={{
                                    pathname: `${history.location.pathname}/certificate-of-insurance`,
                                }}
                            >
                                Resend certificate of insurance
                            </Link>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Link
                                to={{
                                    pathname: `${history.location.pathname}/certificate-of-currency`,
                                }}
                            >
                                Certificate of currency
                            </Link>
                        </Box>
                    </>
                )}
        </>
    )

    return (
        <AdminLayout asideLinks={asideLinks}>
            {!lodaing ? (
                <>
                    {' '}
                    <Heading as="h4" sx={{ mb: 4 }}>
                        Policy Details
                    </Heading>
                    <Box sx={{ mb: 4 }}>
                        <Heading as="h5" sx={{ mb: 2 }}>
                            {policy.product.name}
                        </Heading>
                        <Text>Policy ID: {policy.id}</Text>
                        <Text>Payment Schedule: {policy.paymentSchedule}</Text>
                        {parseFields(
                            JSON.parse(policy.data),
                            product.quoteFlow.fields
                        ).map(([key, val], i) => (
                            <Text key={i + 1}>
                                {key}: {val}
                            </Text>
                        ))}
                    </Box>
                    <Box sx={{ my: 4 }}>
                        <Heading as="h5" sx={{ mb: 2 }}>
                            Period of Insurance
                        </Heading>
                        <Text>
                            Start Date:{' '}
                            {formatDate(
                                policy &&
                                    (JSON.parse(policy.data).startDate ||
                                        policy.startDate)
                            )}
                        </Text>
                        <Text>
                            End Date:{' '}
                            {formatDate(
                                policy &&
                                    (JSON.parse(policy.data).endDate ||
                                        policy.endDate)
                            )}
                        </Text>
                        <Text>
                            Purchased On: {formatDate(policy.createdAt)}
                        </Text>
                        {policy.cancelledAt && (
                            <Text>
                                Cancelled On: {formatDate(policy.cancelledAt)}
                            </Text>
                        )}
                    </Box>
                    <Box sx={{ my: 4 }}>
                        <Heading as="h5" sx={{ mb: 2 }}>
                            Cover details
                        </Heading>
                        <Text>
                            Excess:{' '}
                            {formatCurrency(
                                policy && JSON.parse(policy.data).excess
                            )}
                        </Text>
                        <Text>
                            Premium:{' '}
                            {policy.premium &&
                                formatCurrency(policy.premium.price)}
                        </Text>
                        <Text>
                            Taxes:{' '}
                            {policy.premium &&
                                formatCurrency(policy.premium.tax)}
                        </Text>
                        <Text>
                            Total Premium:{' '}
                            {policy.premium &&
                                formatCurrency(policy.premium.total)}
                        </Text>
                    </Box>
                    {/* <Box sx={{ my: 4 }}>
                <Heading as="h5" sx={{ mb: 2 }}>
                    Documents
                </Heading>
                {product.documents.map((doc) => (
                    <Text key={doc.key}>
                        <Link href={doc.url}>{doc.name}</Link>
                    </Text>
                ))}
            </Box> */}
                    <Divider />
                    <Box sx={{ my: 4 }}>
                        <Heading as="h5" sx={{ mb: 2 }}>
                            Payments
                        </Heading>
                        {policy.payments.items.map((payment) => (
                            <Text key={payment.scheduledDate}>
                                {formatDate(payment.scheduledDate)} -{' '}
                                {formatCurrency(payment.total)} -{' '}
                                {payment.chargedAt ? 'Paid' : 'Scheduled'}
                            </Text>
                        ))}
                    </Box>
                    {!policy.cancelledAt &&
                        policy.paymentMethod &&
                        DateTime.fromISO(policy.endDate) > DateTime.local() && (
                            <>
                                <Divider />
                                <Box sx={{ my: 4 }}>
                                    <Button
                                        onClick={() => {
                                            usedCreditFlag
                                                ? showCreditModal()
                                                : showModal()
                                        }}
                                        variant="outline"
                                        sx={{
                                            color: 'red',
                                            borderColor: 'red',
                                        }}
                                    >
                                        Cancel Policy
                                    </Button>
                                </Box>
                            </>
                        )}
                </>
            ) : (
                <Loading />
            )}
        </AdminLayout>
    )
}

export default Policy
