import React, { useEffect, useState } from 'react'
import {
    Alert,
    Box,
    Divider,
    Flex,
    Heading,
    Label,
    Link,
    Loading,
    Submit,
} from '@sharecover-co/ui'
import { Formik } from 'formik'
import { API, graphqlOperation } from 'aws-amplify'
import { AdminLayout } from '../../layouts'
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations'
import Address from '../../forms/AddressForm'
import Jwt from '../../../lib/jwt'
import { getUserCookie } from './../../../utils/getUserCookie'

const figureName = (profile, token) => {
    return profile && profile.firstName && profile.lastName
        ? `${profile.firstName} ${profile.lastName}`
        : Jwt.getName(token)
}

const Profile = () => {
    const [user, setUser] = useState()
    let token = getUserCookie()
    useEffect(() => {
        API.graphql(graphqlOperation(queries.getProfile)).then(({ data }) =>
            setUser(data.getProfile)
        )
    }, [])

    const submit = async (values) => {
        try {
            // Clean up payload
            delete values.createdAt
            delete values.updatedAt
            delete values.id

            // Update
            await API.graphql(
                graphqlOperation(mutations.updateProfile, { input: values })
            )
        } catch (error) {
            console.log('error:', error)
        }
    }

    const asideLinks = (
        <>
            <Box sx={{ my: 1 }}>
                <Link href={`${process.env.REACT_APP_AUTH_URL}/update-details`}>
                    Update your profile
                </Link>
            </Box>
            <Box sx={{ my: 1 }}>
                <Link href={`${process.env.REACT_APP_AUTH_URL}/reset-password`}>
                    Change your password
                </Link>
            </Box>
            <Box sx={{ my: 1 }}>
                <Link to="/profile/consent">Contact permissions</Link>
            </Box>
            <Box sx={{ my: 1 }}>
                <Link
                    href={`${process.env.REACT_APP_WEB_URL}/privacy-and-security`}
                >
                    Data privacy
                </Link>
            </Box>
        </>
    )

    return (
        <AdminLayout asideLinks={asideLinks}>
            <Heading as="h4" sx={{ mb: 4 }}>
                Your profile
            </Heading>

            {!user && (
                <Flex sx={{ color: 'primary', my: 5 }}>
                    <Loading />
                </Flex>
            )}

            {user && (
                <>
                    <Label description={<p>{figureName(user, token)}</p>}>
                        Name
                    </Label>
                    <Label
                        description={
                            <p>{(user && user.dob) || Jwt.getDob(token)}</p>
                        }
                    >
                        Date of Birth
                    </Label>
                    <Label
                        description={
                            <p>{(user && user.phone) || Jwt.getPhone(token)}</p>
                        }
                    >
                        Phone
                    </Label>
                    <Label
                        description={
                            <p>{(user && user.email) || Jwt.getEmail(token)}</p>
                        }
                    >
                        Email
                    </Label>

                    <Divider sx={{ my: 4 }} />

                    <Heading as="h5" sx={{ my: 4 }}>
                        Update your mailing address
                    </Heading>

                    <Formik
                        enableReinitialize
                        initialValues={user}
                        onSubmit={submit}
                    >
                        {({ errors, handleSubmit }) => (
                            <form onSubmit={handleSubmit}>
                                {errors.name && <Alert>{errors.name}</Alert>}
                                <Address
                                    id="address"
                                    label="Mailing Address"
                                    name="address.formatted"
                                    required
                                />
                                <Submit label="Save" />
                            </form>
                        )}
                    </Formik>
                </>
            )}
        </AdminLayout>
    )
}

export default Profile
