    // Constants for token lifetimes in milliseconds
    const ACCESS_TOKEN_LIFETIME = 10 * 60 * 1000; // 10 minutes in milliseconds
    const INACTIVITY_LIMIT = 10 * 60 * 1000; // 10 minutes in milliseconds
  
    // Variables to store access token expiry time
    let accessTokenExpiryTime = Date.now() + ACCESS_TOKEN_LIFETIME;
  
    let lastActivityTime = Date.now();
    let accessTokenRefreshTimeout = null;
  
    function env() {
      var hostName = window.location.hostname;
      var topDomain = hostName.substring(hostName.lastIndexOf(".") + 1);
      console.log(topDomain);
      return topDomain;
    }

    // Function to redirect to the logout endpoint
    function redirectToLogout() {
      console.warn('Redirecting to logout due to inactivity or token expiry.');
      window.location.href = 'https://secure.sharecover.'+env()+'/cb/logout/sharecover';
    }
  
    // Function to update last activity time and share it across tabs
    function updateLastActivityTime() {
      lastActivityTime = Date.now();
  
      // Update the last activity time in localStorage
      localStorage.setItem('lastActivityTime', lastActivityTime.toString());
    }
  
    // Add event listeners for user activity
    ['mousemove', 'mousedown', 'keydown', 'scroll', 'touchstart'].forEach((event) => {
      document.addEventListener(event, updateLastActivityTime);
    });
  
    // Listen for changes in localStorage (activity in other tabs)
    window.addEventListener('storage', (event) => {
      if (event.key === 'lastActivityTime') {
        lastActivityTime = parseInt(event.newValue, 10);
      }
    });
  
    // Function to check for inactivity across all tabs
    function checkInactivity() {
      const now = Date.now();
      const inactivityDuration = now - lastActivityTime;
  
      if (inactivityDuration > INACTIVITY_LIMIT) {
        console.log('User inactive for more than 10 minutes across all tabs. Redirecting to logout.');
        redirectToLogout();
      }
    }
  
    // Set an interval to check for inactivity periodically
    setInterval(checkInactivity, 60 * 1000); // Check every 1 minute
  
    // Function to refresh the access token
    async function refreshAccessToken() {
      try {
        const now = Date.now();
        const inactivityDuration = now - lastActivityTime;
  
        // Only refresh the token if the user is active in any tab
        if (inactivityDuration > INACTIVITY_LIMIT) {
          console.log('User inactive across all tabs. Not refreshing access token. Redirecting to logout.');
          redirectToLogout();
          return;
        }
  
        const response = await fetch('https://secure.sharecover.'+env()+'/cb/refresh/sharecover', { credentials: 'include' });
  
        if (!response.ok) {
          throw new Error('Failed to refresh access token');
        }
  
        accessTokenExpiryTime = now + ACCESS_TOKEN_LIFETIME;
  
        console.log('Access token refreshed');
  
        // Schedule the next access token refresh
        scheduleAccessTokenRefresh();
      } catch (error) {
        console.error('Error refreshing access token:', error);
        redirectToLogout();
      }
    }
  
    // Function to schedule access token refresh
    function scheduleAccessTokenRefresh() {
      const now = Date.now();
      const timeUntilExpiry = accessTokenExpiryTime - now;
  
      // Clear existing timeout
      if (accessTokenRefreshTimeout) {
        clearTimeout(accessTokenRefreshTimeout);
      }
  
      // Schedule the refresh to happen 1 minute before expiry
      const refreshTime = timeUntilExpiry - 1 * 60 * 1000; // 1 minute before expiry
  
      if (refreshTime <= 0) {
        // Access token has expired or is about to expire
        void refreshAccessToken();
      } else {
        accessTokenRefreshTimeout = setTimeout(() => {
          void refreshAccessToken();
        }, refreshTime);
      }
    }
  
    // Function to initialize session management
    export default function initializeSessionManagement() {
      // Update last activity time
      updateLastActivityTime();
  
      // Schedule access token refresh
      scheduleAccessTokenRefresh();
  
      // Start checking for inactivity
      checkInactivity();
    }
  
    // Call the initialization function
    // initializeSessionManagement();
