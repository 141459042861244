import React, { useState, useEffect } from 'react'
import {
    Box,
    Heading,
    Flex,
    Loading,
    Table,
    Thead,
    Tbody,
    Tr,
    Td,
    Th,
} from '@sharecover-co/ui'
import { AdminLayout } from '../layouts'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../graphql/queries'
import { formatDateTime, formatCurrency } from '../../lib/formatters'

const Billing = () => {
    const [charges, setCharges] = useState()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const {
                    data: {
                        listCharges: { items },
                    },
                } = await API.graphql(graphqlOperation(queries.listCharges))
                setCharges(items)
            } catch (error) {
                console.error(error)
            }
        }
        fetchData()
    }, [])

    return (
        <AdminLayout fullWidth={true}>
            <Box sx={{ mb: 4 }}>
                <Heading as="h4">Your recent charges</Heading>
            </Box>
            {!charges && (
                <Flex sx={{ color: 'primary', my: 5 }}>
                    <Loading />
                </Flex>
            )}
            {charges?.length >= 1 && (
                <Box sx={{ maxWidth: '100%', overflowX: 'scroll' }}>
                    <Table>
                        <Thead>
                            <Tr>
                                <Th>Date</Th>
                                <Th>Reference</Th>
                                <Th>Card</Th>
                                <Th>Refunded</Th>
                                <Th>Amount</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {charges.map((charge) => (
                                <Tr key={charge.id}>
                                    <Td>{formatDateTime(charge.createdAt)}</Td>
                                    <Td>{JSON.parse(charge.meta).ref}</Td>
                                    <Td>**** {charge.paymentMethod.last4}</Td>
                                    <Td>{formatCurrency(charge.refundAmount)}</Td>
                                    <Td>{formatCurrency(charge.amount)}</Td>
                                </Tr>
                            ))}
                        </Tbody>
                    </Table>
                </Box>
            )}
        </AdminLayout>
    )
}

export default Billing
