import React from 'react'
import Button from '@sharecover-co/ui/dist/components/Button'
export default (props) => {
    const bodyRef = React.createRef()
    const createPdf = () => props.createPdf(bodyRef.current)
    return (
        <section className="pdf-container">
            <section className="pdf-toolbar">
                <Button onClick={createPdf}>Print</Button>
            </section>
            <section
                className="pdf-body"
                ref={bodyRef}
                style={{ padding: '16px' }}
            >
                {props.children}
            </section>
        </section>
    )
}
