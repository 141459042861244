import React from 'react'
import { Box, Heading, Link } from '@sharecover-co/ui'

const Aside = ({ links }) => {
    return (
        <>
            <Box variant="boxed">
                <Heading as="h5" sx={{ mb: 2 }}>
                    Quick Links
                </Heading>
                {links ? links : (
                    <>
                        <Box sx={{ my: 1 }}>
                            <Link
                                href={`${process.env.REACT_APP_WEB_URL}/contact`}
                            >
                                Contact Us
                            </Link>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Link href={process.env.REACT_APP_WEB_URL}>
                                Purchase a new policy
                            </Link>
                        </Box>
                        <Box sx={{ my: 1 }}>
                            <Link
                                href={`${process.env.REACT_APP_WEB_URL}/product-disclosures`}
                            >
                                Read our FAQ
                            </Link>
                        </Box>
                    </>
                )}
            </Box>

            {/* <Box sx={{ p: [2, 3, 4], mt: 1 }}>
                <Heading as="h5" sx={{ mb: 2 }}>
                    Referral Program
                </Heading>
                <Text sx={{ mb: 2 }}>ShareCover's Referral program rewards code-sharers with free insurance.</Text>
                <Box sx={{ my: 1 }}>
                    <Link to="/refer">
                        Send a referral
                    </Link>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Link to="/refer">
                        View my referrals
                    </Link>
                </Box>
                <Box sx={{ my: 1 }}>
                    <Link href={`${process.env.REACT_APP_WEB_URL}/product-disclosures`}>
                        Read our FAQ
                    </Link>
                </Box>
            </Box> */}
        </>
    )
}

export default Aside
