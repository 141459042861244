import { theme } from '@sharecover-co/ui'
import merge from 'lodash.merge'

const base = {
    forms: {
        input: {
            width: '100%',
            lineHeight: 'inherit'
        }
    }
}

export default merge(theme, base)
