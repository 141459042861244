import React from 'react'
import { Heading, Box, Button } from '@sharecover-co/ui'
import { API, graphqlOperation } from 'aws-amplify'
import { useHistory } from 'react-router-dom'
import { nanoid } from 'nanoid'
import { AdminLayout } from '../../layouts'
import AdyenForm from '../../forms/AdyenForm'
import * as mutations from '../../../graphql/mutations'

const Billing = () => {
    const history = useHistory()

    return (
        <AdminLayout>
            <Heading as="h4" sx={{ mb: 4 }}>
                New Payment Method
            </Heading>
            <AdyenForm
                onSubmit={async (card) => {
                    const input = {
                        id: nanoid(),
                        gateway: 'CLP',
                        token: card.paymentAccountId,
                        cardType: card.cardInfo.cardBrand,
                        last4: card.cardInfo.lastFour,
                        expirationMonth: card.cardInfo.expiryMonth,
                        expirationYear: card.cardInfo.expiryYear,
                    }

                    await API.graphql(
                        graphqlOperation(mutations.addPaymentMethod, { input })
                    )

                    history.goBack()
                }}
            />
            <Box sx={{ my: 3 }}>
                <Button onClick={() => history.goBack()} variant="outline">
                    Back
                </Button>
            </Box>
        </AdminLayout>
    )
}

export default Billing
