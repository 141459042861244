import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import PoliciesList from './PoliciesList'
import { AdminLayout } from '../../layouts'
import * as queries from '../../../graphql/queries'

const sortByStartDateDesc = (policies) =>
    policies.sort(
        (a, b) =>
            new Date(`${b.startDate} ${b.startTime}`) -
            new Date(`${a.startDate} ${a.startTime}`)
    )

const Dashboard = () => {
    const [homePolicies, setHomePolicies] = useState()
    const [longHomePolicies, setLongHomePolicies] = useState()
    const [motorPolicies, setMotorPolicies] = useState()

    useEffect(() => {
        API.graphql(graphqlOperation(queries.listPolicies))
            .then((res) => {
                const sortedPolicies = sortByStartDateDesc(
                    res.data.listPolicies
                )
                setHomePolicies(
                    sortedPolicies.filter(
                        (policy) => policy.id.indexOf('HOM-') === 0
                    )
                )
                setMotorPolicies(
                    sortedPolicies.filter(
                        (policy) => policy.id.indexOf('MOT-') === 0
                    )
                )
                setLongHomePolicies(
                    sortedPolicies.filter(
                        (policy) => policy.id.indexOf('LHOM-') === 0
                    )
                )
            })
            .catch((error) => console.log(error))
    }, [])

    return (
        <AdminLayout>
            <PoliciesList type={'home'} policies={homePolicies} />
            <PoliciesList type={'long home'} policies={longHomePolicies} />
            <PoliciesList type={'motor'} policies={motorPolicies} />
        </AdminLayout>
    )
}

export default Dashboard
