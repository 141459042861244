import React, { useEffect, useState } from 'react'
import { Alert, Heading, Submit, Box, Link, Radio } from '@sharecover-co/ui'
import { Formik } from 'formik'
import { API, graphqlOperation } from 'aws-amplify'
import { useParams, useHistory } from 'react-router-dom'
// import { nanoid } from 'nanoid'
import { AdminLayout } from '../../layouts'
import * as queries from '../../../graphql/queries'
import * as mutations from '../../../graphql/mutations'

const Billing = () => {
    const [paymentMethods, setPaymentMethods] = useState([])
    const [policy, setPolicy] = useState()
    const { id } = useParams()
    const history = useHistory()

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [
                    { data: policy },
                    { data: methods },
                ] = await Promise.all([
                    API.graphql(graphqlOperation(queries.getPolicy, { id })),
                    API.graphql(graphqlOperation(queries.listPaymentMethods)),
                ])
                setPolicy(policy.getPolicy)
                setPaymentMethods(methods.listPaymentMethods)
            } catch (error) {
                console.error(error)
            }
        }

        fetchData()
    }, [id])

    const choices = Object.fromEntries(
        paymentMethods.map((m) => [
            m.id,
            `${m.cardType} ending in ${m.last4} (${m.expirationMonth}/${m.expirationYear})`,
        ])
    )

    // Save payment details against policy
    const submit = async (values) => {
        try {
            console.log('values:', values)
            const input = { id, ...values }
            await API.graphql(
                graphqlOperation(mutations.updatePolicyPaymentMethod, { input })
            )
            history.push(`/policy/${id}`)
        } catch (error) {
            console.error(error)
        }
    }

    return (
        <AdminLayout>
            <Heading as="h4" sx={{ mb: 4 }}>
                Select a payment method
            </Heading>
            {paymentMethods && policy && (
                <Formik
                    enableReinitialize
                    initialValues={{ paymentMethodId: policy.paymentMethodId }}
                    onSubmit={submit}
                >
                    {({ errors, handleSubmit }) => (
                        <form onSubmit={handleSubmit}>
                            {errors.name && <Alert>{errors.name}</Alert>}
                            <Radio
                                name="paymentMethodId"
                                label="Change your payment details"
                                required
                                choices={choices}
                            />
                            <Box sx={{ my: 3 }}>
                                <Link to={`/policy/${id}/payment-method/new`}>
                                    Add new payment method
                                </Link>
                            </Box>
                            <Submit label="Save" />
                        </form>
                    )}
                </Formik>
            )}
            <Box sx={{ my: 3 }}>
                <Link to={`/policy/${id}`} sx={{ variant: 'links.buttonOutline' }}>
                    Back
                </Link>
            </Box>
        </AdminLayout>
    )
}

export default Billing
