import React, { useEffect, useState } from 'react'
import { AdminLayout } from '../../layouts'
import { API, graphqlOperation } from 'aws-amplify'
import * as queries from '../../../graphql/queries'
import { Form, Formik } from 'formik'
import filterData from './filterData'
import Doc from '../../../pdf/DocService'
import PdfContainer from '../../../pdf/PdfContainer'
import Table from './Table'
import { Loading } from '@sharecover-co/ui'
import YearSelector from './YearSelector'
const FinancialYear = () => {
    const [policies, setPolicies] = useState(null)
    const [finalData, setFinalData] = useState([])

    useEffect(() => {
        API.graphql(graphqlOperation(queries.listPolicies))
            .then(({ data }) => {
                setPolicies(data.listPolicies)
                const filtredData = filterData(data.listPolicies, '2022-2023')
                setFinalData(filtredData)
            })

            .catch((error) => console.log(error))
    }, [])
    const createPdf = (html) => Doc.createPdf(html)

    const onYearChange = async (value) => {
        const filtredData = filterData(policies, value)
        setFinalData(filtredData)
    }

    return (
        <AdminLayout>
            <Formik
                initialValues={{
                    finacialYear: '2022-2023',
                }}
            >
                <Form>
                    <YearSelector onYearChange={onYearChange} />
                    {policies ? (
                        <>
                            {finalData.length !== 0 ? (
                                <PdfContainer createPdf={createPdf}>
                                    <br />
                                    <br />
                                    <Table data={finalData} />
                                </PdfContainer>
                            ) : (
                                <>No policy found!</>
                            )}
                        </>
                    ) : (
                        <Loading />
                    )}
                </Form>
            </Formik>
        </AdminLayout>
    )
}

export default FinancialYear
