import { DateTime } from 'luxon'

export const formatDate = (val) =>
    DateTime.fromISO(val).toLocaleString(DateTime.DATE_HUGE)

export const formatDateTime = (val) => DateTime.fromISO(val).toLocaleString(DateTime.DATETIME_MED)

export const formatCurrency = (n) =>
    new Intl.NumberFormat('en-AU', {
        style: 'currency',
        currency: 'AUD',
    }).format(n)
