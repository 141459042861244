import React from 'react'
import PropTypes from 'prop-types'
import { Flex, Box, Header, Link } from '@sharecover-co/ui'
import Aside from './Aside'

const Menu = () => {
    // Auth Signout
    const signOut = async (e) => {
        try {
            e.preventDefault()
            window.location = `${process.env.REACT_APP_AUTH_URL}/logout?redirect_idx=0`
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Flex
            sx={{
                overflowX: 'scroll',
                marginLeft: '-30px',
                marginRight: '-30px',
                width: ['calc(100vw - 220px)', 'calc(100vw - 260px)'],
            }}
        >
            <Link to="/" sx={{ p: [1, 2] }}>
                Policies
            </Link>
            <Link to="/claims" sx={{ p: [1, 2] }}>
                Claims
            </Link>
            <Link to="/payments" sx={{ p: [1, 2] }}>
                Payments
            </Link>
            <Link to="/profile" sx={{ p: [1, 2] }}>
                Profile
            </Link>
            <Link to="/financials" sx={{ p: [1, 2] }}>
                Financial Year
            </Link>
            <Flex
                sx={{
                    flex: ['', 1],
                    justifyContent: ['', 'flex-end'],
                    ml: ['0', '0', '40px'],
                }}
            >
                <Link onClick={(e) => signOut(e)} href="#" sx={{ p: [1, 2] }}>
                    Logout
                </Link>
            </Flex>
        </Flex>
    )
}

const AdminLayout = ({ menu, children, fullWidth, asideLinks }) => (
    <Box>
        <Header title={menu} logoLink={process.env.REACT_APP_WEB_URL} />
        <Flex
            variant="fixed"
            sx={{
                p: [0, 0, 0, 0, 2],
                justifyContent: 'space-evenly',
                flexDirection: ['column', 'column', 'row'],
            }}
        >
            <Box
                sx={{
                    p: [3, 4],
                    flex: 1,
                }}
            >
                {children}
            </Box>
            {!fullWidth && (
                <Box
                    sx={{
                        p: [3, 4],
                        flex: 1,
                        maxWidth: ['100%', '100%', 600],
                    }}
                >
                    <Aside links={asideLinks} />
                </Box>
            )}
        </Flex>
    </Box>
)

AdminLayout.propTypes = {
    children: PropTypes.any.isRequired,
    menu: PropTypes.node,
}

AdminLayout.defaultProps = {
    menu: <Menu />,
}

export default AdminLayout
