import { uniqBy, get } from 'lodash'
import moment from 'moment'

const filterData = (data, time) => {
    const UniqData = uniqBy(data, 'id')
    const [startDate, endDate] = time.split('-')

    let in_range_data = UniqData.filter((p) =>
        moment(p.createdAt, 'YYYY/MM/DD').isBetween(
            moment(`01/07/${startDate}`, 'DD/MM/YYYY'),
            moment(`30/06/${endDate}`, 'DD/MM/YYYY'),
            'days',
            '[]'
        )
    )

    in_range_data = in_range_data.filter((p) => !p.cancelledAt)
    console.log(in_range_data)
    return in_range_data.map((p) => {
        let gst = get(p, 'premium.taxes', []).find((item) => item.tax === 'GST')
        let esl = get(p, 'premium.taxes', []).find((item) => item.tax === 'ESL')
        let stampDuty = get(p, 'premium.taxes', []).find(
            (item) => item.tax === 'Stamp Duty'
        )

        return {
            id: get(p, 'id', ''),
            premium: get(p, 'premium.price', ''),
            createdAt: get(p, 'createdAt', ''),
            card: `**** ${get(p, 'paymentMethod.last4', '')}`,
            gst: get(gst, 'price', ''),
            esl: get(esl, 'price', ''),
            stampDuty: get(stampDuty, 'price', ''),
        }
    })
}
export default filterData
