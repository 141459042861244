import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
    Box,
    Heading,
    Link,
    Flex,
    Loading,
    Submit,
    Text,
    Field,
    Alert,
} from '@sharecover-co/ui'
import { Formik } from 'formik'
import { API, graphqlOperation } from 'aws-amplify'
import { AdminLayout } from '../../layouts'
import * as queries from '../../../graphql/queries'

const COI = () => {
    const [loading, isLoading] = useState(false)
    const [sent, isSent] = useState(false)
    const { id } = useParams()
    const [user, setUser] = useState()

    useEffect(() => {
        API.graphql(graphqlOperation(queries.getProfile)).then(({ data }) =>
            setUser(data.getProfile)
        )
    }, [])

    const submit = async (values) => {
        try {
            isLoading(true)
            isSent(false)
            await API.graphql(
                graphqlOperation(queries.resendCOI, { id, ...values })
            )
            isLoading(false)
            isSent(true)
        } catch (error) {
            console.log('error:', error)
        }
    }

    return (
        <AdminLayout>
            <Heading as="h4">Resend Certificate of Insurance</Heading>

            {sent && (
                <Box sx={{ my: 4 }}>
                    <Alert variant="success" sz={{ color: 'white' }}>
                        Your email has been sent!
                    </Alert>
                </Box>
            )}

            {user && (
                <Box sx={{ my: 4 }}>
                    <Text>
                        To send your Certificate Of Insurance and related
                        documents, please enter an email below.
                    </Text>

                    {loading ? (
                        <Flex sx={{ color: 'primary', my: 5 }}>
                            <Loading />
                        </Flex>
                    ) : (
                        <Formik
                            initialValues={{
                                email: user.email,
                            }}
                            onSubmit={submit}
                        >
                            {({ handleSubmit }) => (
                                <form onSubmit={handleSubmit}>
                                    <Field
                                        label="Email Address"
                                        id="email"
                                        name="email"
                                        type="email"
                                        required
                                    />
                                    <Submit />
                                </form>
                            )}
                        </Formik>
                    )}
                </Box>
            )}

            <Box sx={{ my: 5 }}>
                <Link
                    to={`/policy/${id}`}
                    sx={{ variant: 'links.buttonOutline' }}
                >
                    Back
                </Link>
            </Box>
        </AdminLayout>
    )
}

export default COI
