import React, { useEffect } from 'react'
import Routes from './Routes'
import initializeSessionManagement from './Refresh'

const App = () => {
    useEffect(() => {
        initializeSessionManagement();
    }, [])
    return (<Routes />)
}
export default App
