import React from 'react'
import PropTypes from 'prop-types'
import set from 'lodash.set'
import { Box, Input, Field, Label, Button } from '@sharecover-co/ui'
import { useField, useFormikContext } from 'formik'
import PlacesAutocomplete, { geocodeByPlaceId } from 'react-places-autocomplete'

const parseAddressComponents = (result) => {
    // parse address_components
    let location = {
        streetAddress: '',
        suiteAddress: '',
        town: '',
        city: '',
        region: '',
        postcode: '',
        country: '',
    }

    for (let i = 0; i < result.address_components.length; i++) {
        let component = result.address_components[i]

        if (component.types.includes('street_number')) {
            location.streetAddress = [
                component.short_name,
                location.streetAddress,
            ]
                .filter((f) => f)
                .join(' ')
        } else if (component.types.includes('route')) {
            location.streetAddress = [
                location.streetAddress,
                component.long_name,
            ]
                .filter((f) => f)
                .join(' ')
        } else if (component.types.includes('sublocality')) {
            location.town = component.long_name
        } else if (component.types.includes('locality')) {
            location.city = component.long_name
        } else if (component.types.includes('administrative_area_level_1')) {
            location.region = component.short_name
        } else if (component.types.includes('postal_code')) {
            location.postcode = component.short_name
        } else if (component.types.includes('country')) {
            location.country = component.short_name
        }
    }

    return location
}

const formatAddress = (data) =>
    [
        [data.suiteAddress, data.streetAddress].filter((f) => f).join('/'),
        data.town,
        data.city,
        [data.region, data.postcode].filter((f) => f).join(' '),
        data.country,
    ]
        .filter((f) => f)
        .join(', ')

const Address = ({ label, required, ...props }) => {
    const [field, meta] = useField(props)
    const { values, setFieldValue } = useFormikContext()

    // allow nesting
    const prefix = field.name.split('.').slice(0, -1).join('.')
    const setValue = (k, v) => setFieldValue([prefix, k].join('.'), v)

    // on change handler to produce address format
    const onChange = (e) => {
        field.onChange(e)

        const newValues = { ...values }
        set(newValues, e.target.name, e.target.value)

        const address = formatAddress(newValues[prefix])

        if (address) {
            setValue('formatted', address)
        }
    }

    if (values[prefix]?.isManualAddress) {
        return (
            <Box sx={{ my: 3 }}>
                <Label
                    sx={{
                        display: 'block',
                        fontSize: 20,
                        fontWeight: 'heading',
                    }}
                >
                    {label} {required && <span className="req">*</span>}
                </Label>
                <Button
                    variant="text"
                    type="button"
                    onClick={() => setValue('isManualAddress', false)}
                    sx={{ mb: 0 }}
                >
                    Search for your address using Google.
                </Button>
                <Field
                    name={`${prefix}.streetAddress`}
                    label="Street Address"
                    required
                    onChange={onChange}
                    placeholder="123 Jones Rd"
                />
                <Field
                    name={`${prefix}.suiteAddress`}
                    onChange={onChange}
                    label="Apartment, suite, etc."
                />
                <Field
                    name={`${prefix}.town`}
                    onChange={onChange}
                    label="Town"
                />
                <Field
                    name={`${prefix}.city`}
                    onChange={onChange}
                    label="City"
                    required
                />
                <Field
                    name={`${prefix}.region`}
                    onChange={onChange}
                    label="Region"
                />
                <Field
                    name={`${prefix}.postcode`}
                    onChange={onChange}
                    required
                    label="Postcode"
                />
                <Field
                    name={`${prefix}.country`}
                    onChange={onChange}
                    required
                    label="Country"
                />
            </Box>
        )
    }

    return (
        <Box sx={{ my: 3 }}>
            <Label
                htmlFor={props.id || props.name}
                sx={{
                    display: 'block',
                    fontSize: 20,
                    fontWeight: 'heading',
                }}
            >
                {label} {required && <span className="req">*</span>}
            </Label>
            <Box sx={{ mt: 1, mb: 4 }}>
                <PlacesAutocomplete
                    searchOptions={{
                        location: {
                            lat: () => -34,
                            lng: () => 151,
                        },
                        radius: 4000,
                    }}
                    {...field}
                    {...props}
                    onChange={(value) => setFieldValue(field.name, value)}
                    onSelect={async (address, placeId) => {
                        if (!placeId) {
                            return
                        }

                        const [result] = await geocodeByPlaceId(placeId)

                        setValue('formatted', result.formatted_address)
                        setValue('placeId', result.place_id)

                        // components
                        const components = parseAddressComponents(result)

                        Object.entries(components).map(([key, value]) =>
                            setValue(key, value)
                        )

                        // lat/lon
                        setValue('lat', result.geometry.location.lat)
                        setValue('lng', result.geometry.location.lng)
                    }}
                >
                    {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                    }) => (
                        <div>
                            <Input
                                {...getInputProps({
                                    placeholder: 'Search address',
                                    className: 'location-search-input',
                                })}
                            />
                            <div className="autocomplete-dropdown-container">
                                {loading && <div>Loading...</div>}
                                {suggestions.map((suggestion) => {
                                    return (
                                        <Box
                                            sx={{
                                                p: 2,
                                                my: 1,
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                bg: suggestion.active
                                                    ? 'lightGrey'
                                                    : 'snowWhite',
                                            }}
                                            {...getSuggestionItemProps(
                                                suggestion
                                            )}
                                        >
                                            <span>
                                                {suggestion.description}
                                            </span>
                                        </Box>
                                    )
                                })}
                            </div>
                        </div>
                    )}
                </PlacesAutocomplete>
                {meta.touched && meta.error ? (
                    <div style={{ color: 'red', marginTop: '.5rem' }}>
                        {meta.error}
                    </div>
                ) : null}
                <Button
                    variant="text"
                    type="button"
                    onClick={() => setValue('isManualAddress', true)}
                    sx={{ mb: 0 }}
                >
                    Can't find your address? Click here to enter it manually.
                </Button>
            </Box>
        </Box>
    )
}

Address.propTypes = {
    label: PropTypes.string,
    required: PropTypes.bool,
}

Address.defaultProps = {
    label: 'Address',
    required: false,
}

export default Address
