import React from 'react'
import {
    Tbody,
    Td,
    Th,
    Thead,
    Tr,
} from '@sharecover-co/ui/dist/components/Table'
import moment from 'moment'
const Table = (props) => {
    return (
        <table id="topdf" style={{ width: '100%' }}>
            <Thead>
                <Tr>
                    <Th style={{ padding: '0 100px' }}>Transaction Date</Th>
                    <Th style={{ padding: '0 80px' }}>Reference</Th>
                    <Th style={{ padding: '0 50px' }}>Card</Th>
                    <Th style={{ padding: '0 50px' }}>Amount</Th>
                    <Th style={{ padding: '0 50px' }}>GST</Th>
                    <Th style={{ padding: '0 50px' }}>ESL/FSL</Th>
                    <Th style={{ padding: '0 50px' }}>Stamp Duty</Th>
                </Tr>
            </Thead>
            <Tbody>
                {props.data.map((item, i) => (
                    <Tr key={i}>
                        <Td>{`${moment(new Date(item.createdAt)).format(
                            'DD MMM yyyy, hh:mm'
                        )}`}</Td>
                        <Td>{item.id}</Td>
                        <Td>{item.card}</Td>
                        <Td>$ {item.premium}</Td>
                        <Td>$ {item.gst}</Td>
                        <Td>$ {item.esl}</Td>
                        <Td>$ {item.stampDuty}</Td>
                    </Tr>
                ))}
            </Tbody>
        </table>
    )
}

export default Table
