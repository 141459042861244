import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Box, Heading, Link, Flex, Loading } from '@sharecover-co/ui'
import { API } from 'aws-amplify'
import { AdminLayout } from '../../layouts'

const COC = () => {
    const [doc, setDoc] = useState()
    const { id } = useParams()

    useEffect(() => {
        const requestCertificate = async () => {
            try {
                // Create doc
                const response = await API.get('API', `/coc/${id}`, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                })
                // Set doc data
                setDoc(response)
            } catch (error) {
                setDoc(undefined)
                console.log(error)
            }
        }
        requestCertificate()
    }, [id])

    return (
        <AdminLayout>
            <Heading as="h4">Certificate of Currency</Heading>

            {!doc ? (
                <Flex sx={{ color: 'primary', my: 5 }}>
                    <Loading />
                </Flex>
            ) : (
                <Box sx={{ my: 4 }}>
                    {doc?.url && (
                        <Link href={doc.url}>
                            Policy {id} - Certificate of Currency.pdf
                        </Link>
                    )}
                </Box>
            )}

            <Box sx={{ my: 5 }}>
                <Link
                    to={`/policy/${id}`}
                    sx={{ variant: 'links.buttonOutline' }}
                >
                    Back
                </Link>
            </Box>
        </AdminLayout>
    )
}

export default COC
