import React from 'react'
import {
    Heading,
    Flex,
    Box,
    Link,
    Loading,
    Text,
    Badge,
} from '@sharecover-co/ui'
import { DateTime, Interval } from 'luxon'
import { formatDate } from '../../../lib/formatters'

const PoliciesList = ({ type, policies }) => (
    <div>
        <Heading as="h4" sx={{ mb: 4 }}>
            Your {type} policies
        </Heading>

        {!policies && (
            <Flex sx={{ color: 'primary', my: 5 }}>
                <Loading />
            </Flex>
        )}

        {policies?.length === 0 && (
            <Flex sx={{ color: 'primary', my: 5 }}>
                <Heading as="h5">No {type} policies to display</Heading>
            </Flex>
        )}

        {policies?.map((policy, i) => (
            <Box sx={{ my: 4 }} key={policy.id}>
                <Link to={`/policy/${policy.id}`}>
                    <Heading
                        as="h5"
                        sx={{
                            color: 'secondary',
                            ':hover': { color: 'primary' },
                        }}
                    >
                        {policy.product.name}
                    </Heading>
                </Link>
                <Text>
                    {formatDate(policy.startDate)} until{' '}
                    {formatDate(policy.endDate)}
                </Text>
                <Box>
                    {Interval.fromDateTimes(
                        DateTime.fromISO(policy.startDate),
                        DateTime.fromISO(policy.endDate)
                    ).contains(DateTime.local()) &&
                        !policy.cancelledAt && (
                            <Badge
                                sx={{
                                    backgroundColor: 'lightGrey',
                                    color: 'text',
                                    fontSize: 12,
                                    fontWeight: 500,
                                    padding: '4px 5px 0',
                                    marginRight: '10px',
                                }}
                            >
                                Active
                            </Badge>
                        )}

                    <Badge
                        sx={{
                            backgroundColor: 'lightGrey',
                            color: 'text',
                            fontSize: 12,
                            fontWeight: 500,
                            padding: '4px 5px 0',
                            marginRight: '10px',
                        }}
                    >
                        Policy {policy.id}
                    </Badge>

                    {DateTime.local() < DateTime.fromISO(policy.startDate) &&
                        !policy.cancelledAt && (
                            <Badge
                                sx={{
                                    backgroundColor: 'lightGrey',
                                    color: 'text',
                                    fontSize: 12,
                                    fontWeight: 500,
                                    padding: '4px 5px 0',
                                    marginRight: '10px',
                                }}
                            >
                                Starting soon
                            </Badge>
                        )}

                    {policy.cancelledAt && (
                        <Badge
                            sx={{
                                backgroundColor: 'snowWhite',
                                color: 'text',
                                fontSize: 12,
                                fontWeight: 500,
                                padding: '4px 5px 0',
                                marginRight: '10px',
                            }}
                        >
                            Cancelled
                        </Badge>
                    )}
                </Box>
            </Box>
        ))}
    </div>
)

export default PoliciesList
