import Cookies from 'js-cookie'

export function getUserCookie() {
    let token = Cookies.get('x-iag-customer-id', {
        domain: process.env.REACT_APP_COGNITO_DOMAIN,
    })
    if (!token)
        token = Cookies.get('x-iag-user-id', {
            domain: `.${process.env.REACT_APP_DOMAIN_NAME}`,
        })

    return token
}
